@import "../../scss/variables.scss";

.pageHeader {
  // width: 100vw;
  margin: 0 auto 93px;
  text-align: center;
  padding: 20px 25px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.8s ease-in-out;
  -webkit-transition: 0.8s ease-in-out;
  z-index: 999;
}

.logo {
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.8s ease-in-out;
  -webkit-transition: 0.8s ease-in-out;
  z-index: 999;
}