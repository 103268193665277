@import "../../scss/variables.scss";

.button {
    background: transparent;
    font-family: $Commissioner;
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    /* Dark */
    color: var(--color-dark);
    padding: 30px 71px;
    border: 2px solid var(--color-dark);
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 100px;
    position: relative;
    transition: all 350ms linear;
    z-index: 99;
    overflow: hidden;
    &::after {
        content: url("../../static/arrow.svg");
        position: relative;
        margin-left: 8px;
        left: 0;
        transition: all 350ms linear;
    }
    &::before {
        font-size: 0;
        z-index: -1;
        content: "";
        position: absolute;
        bottom: -120px;
        transform: skew(-60deg, -166deg);
        left: 0;
        border-radius: 100px;
        width: 100%;
        height: 100%;
        transition: all 350ms linear;
        background-color: var(--color-orange);
    }
    &:hover::after {
        left: 12px;
        filter: brightness(0) invert(1);
    }
    &:hover {
        color: var(--color-white);
        border: 2px solid transparent !important;
    }
    &:hover::before {
        bottom: 0;
        border-radius: 3rem;
        transform: skew(-83deg, -150deg);
    }
}
