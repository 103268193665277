@import "reset.scss";
@import "variables.scss";

.App {
  text-align: left;
  margin: 90px auto;
  margin: 2rem 12rem;
}
textarea {
  font-size: 16px;
}
input {
  border-color: var(--color-dark);
  transition: all 0.15s;
}
input:focus {
  border-color: var(--color-orange);
}
// ul {
//   text-align: left;
// }
// li {
//   /* Desktop/Body Huge */

//   font-family: "Commissioner";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 130%;
//   /* identical to box height, or 26px */

//   /* White */

//   color: var(--color-white);
//   text-align: left;
//   margin: 0.8rem 0;
// }
.container {
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100rem;
  padding-inline: 1rem;
}
h5 {
  /* Desktop/H7 */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* White */

  color: var(--color-white);
}
@media screen and (max-width: 1024px) {
  p {
    font-size: 1.2rem;
  }
  li {
    font-size: 1rem;
  }
  h5 {
    font-size: 0.75rem;
  }
  h1 {
    font-size: 6rem;
    text-align: left;
  }
  textarea {
    max-width: 100%;
  }
}
.line {
  background: var(--color-body);
  height: 2px;
}
// Grid
.grid {
  display: grid;
}
.grid--1x2 {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.grid--1x2-strict {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.grid--1x2-mob {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}
@media screen and (max-width: 1024px) {
  .grid--1x2-mob {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}
input[type="file"] {
  display: none;
}
.grid--1x3 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}
// @media screen and (max-width: 1400px) {
//   .grid--1x3 {
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 1rem;
//   }
// }
@media screen and (max-width: 1024px) {
  .grid--1x3 {
    grid-template-columns: 1fr;
  }
  .grid--1x2 {
    grid-template-columns: 1fr;
  }
}
// Buttons
.btn {
  background: transparent;

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 130%;

  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* Dark */

  color: var(--color-dark);
  padding: 1.3rem 0;
  margin: 1rem 0;
}
.btn--border {
  border: 1px solid var(--color-dark);
}
.btn--round {
  border-radius: 3rem;
}
.btn--arrow-right::after {
  content: url("../static/arrow.svg");
  padding-left: 0.8rem;
  transition: 0.2s padding-left;
}
.btn--arrow-right:hover::after {
  padding-left: 1.5rem;
}
.btn--with-arrow {
  border: 1px solid var(--color-body);
  border-radius: 100%;
  padding: 1rem;
  background: transparent;
}
.btn--circle {
  position: relative;
  display: inline-block;
  padding: 15px 70px;
  width: 15rem;
  height: 15rem;
  border-radius: 100%;

  font-family: arial;
  background-image: linear-gradient(#b17461, #b17461);
  background-repeat: no-repeat;
  transition: background-size 0.5s, color 0.5s;
  background-size: 0% 0%;
}

.btn--circle:hover {
  background-size: 100% 100%;
  background-position: 50% 50%;
  color: #fff;
}
.btn--circle-animated {
  border: 1px solid var(--color-dark);
  position: absolute;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  display: none;
  transition: 0.2s opacity;
  width: 13rem;
  height: 13rem;
  border-radius: 100%;
  background-color: var(--color-white);
  // position: relative;
  overflow: hidden;
  display: inline-block;
  color: var(--color-dark);
  text-transform: uppercase;
}
.btn--circle-animated-light {
  color: var(--color-body);
  border: 1px solid var(--color-body);
}
.btn--circle-animated-light .btn--circle-text {
  color: var(--color-body);
}
.btn--circle-animated-light .btn--circle-animated-bg {
}
.btn--details {
  border-radius: 3rem;
  color: var(--color-white);
  background: var(--color-dark);
  padding: 1rem;
}
.btn--circle-animated-bg {
  width: 0;
  background: transparent;

  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  background-image: linear-gradient(var(--color-orange), var(--color-orange));
  transition: 0.5s;
  z-index: -1;
  border-radius: 50%;
}
.btn--circle-text {
  position: relative;
  bottom: 0%;
}
.btn--circle-animated:hover {
  color: var(--color-white);
  border: none;
}

.btn--circle-animated:hover .btn--circle-animated-bg {
  width: 100%;
  height: 100%;
}
.btn--attachment {
  cursor: pointer;
  text-align: center;
  padding: 0.3rem;
}
.btn--wave {
  background: transparent;

  border-spacing: 0;
  border-collapse: separate;
  border-radius: 100px;
  position: relative;
  transition: all 350ms linear;
  z-index: 99;
  overflow: hidden;

  &::before {
    font-size: 0;
    z-index: -1;
    content: "";
    position: absolute;
    bottom: -120px;
    transform: skew(-60deg, -166deg);
    left: 0;
    border-radius: 100px;
    width: 100%;
    height: 100%;
    transition: all 350ms linear;
    background-color: var(--color-orange);
  }
  &:hover::after {
    left: 12px;
    filter: brightness(0) invert(1);
  }
  &:hover {
    color: var(--color-white);
    border: 2px solid transparent !important;
  }
  &:hover::before {
    bottom: 0;
    border-radius: 3rem;
    transform: skew(-83deg, -150deg);
  }
}
@media screen and (max-width: 1024px) {
  .btn--attachment {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.btn--footer {
  color: var(--color-white);
  border-color: var(--color-white);
  position: absolute;
  bottom: -3rem;
  left: 35%;
  z-index: 10;
  width: 13rem;
  height: 13rem;
  padding: 3rem;
}
@media screen and (max-width: 1024px) {
  .btn--footer-mob {
    display: none;
  }
}
// About

.about-us--article {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
  }
}
.about-us--description {
  max-width: 28rem;
  @media screen and (max-width: 1024px) {
    margin: 0 auto;
    max-width: 31rem;
    width: 100%;
  }
  @media screen and (min-width: 2000px) {
    width: 60rem;
    margin-right: 2rem;
  }
}

.about-us--description p {
  margin-bottom: 1rem;
}

.about-us--title {
  width: 60rem;
  @media screen and (max-width: 1024px) {
  }
}
@media screen and (max-width: 1024px) {
  .about-us--title h2 {
    font-size: 3rem;
    margin: 1rem 0;
  }
}
// Proposal
.proposal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10rem 0;
}
.proposal-long {
  flex-direction: column;
  margin: 1rem;
}
.proposal-long .proposal--text {
  margin-bottom: 2rem;
}
@media screen and (max-width: 1024px) {
  .proposal {
    flex-direction: column;
  }
  .proposal-long .proposal--text {
    margin-bottom: 2rem;
    margin-top: 9rem;
  }
}
.proposal--text {
  width: 20rem;
}
.proposal--text h3 {
  margin-bottom: 0.5rem;
}
.proposal-short--text {
  margin-bottom: 1.5rem;
}
.proposal--text-additional {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}
.proposal--adds-container {
  margin-top: 3rem;
  @media screen and (max-width: 1024px) {
    margin: 3rem 0;
    margin-top: -2rem;
  }
}
.proposal--adds {
  margin: 1rem 0;
}

// Form
.contact--form {
  display: flex;
  flex-direction: row;
  scroll-behavior: smooth;
}
.contact--form-long {
  flex-direction: column;
  width: 27rem;
}
.form--input {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.contact--inputs {
  margin-right: 1.5rem;
}
.contact--inputs div label {
  margin-bottom: -1rem;
}
.contact--inputs div input {
  border-bottom: 1px solid var(--color-dark);
  padding: 1rem 0;
  margin: 1rem 0;
  background-color: transparent;
}
.contact--textarea textarea {
  background: rgba(43, 43, 43, 0.05);
  height: 8rem;
  width: 27rem;
  padding: 1rem;
  margin-top: 0.2rem;
  box-sizing: border-box;
}

.proposal-short {
  margin: 1rem;
}
@media screen and (max-width: 1024px) {
  .proposal {
    margin-top: -7rem;
  }
  .contact--form-long {
    width: 100%;
  }
  .proposal--text {
    margin-bottom: 2rem;
  }
  .proposal--text p {
    font-size: 1.2rem;
  }
  .contact--form {
    flex-direction: column;
  }
  label {
    font-size: 1.35rem;
  }
  ::placeholder {
    font-size: 1.35rem;
  }
  .contact--textarea textarea {
    margin-top: 0.5rem;
  }
  input,
  textarea {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .contact--inputs div input {
    width: 15rem;
    padding-right: 1rem;
  }
  .contact--form-long div input {
    width: 100%;
  }
}

.contact--form div {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.contact--inputs {
  margin-right: 1.5rem;
}
.contact--inputs div label {
  margin-bottom: -1rem;
}
.contact--inputs div input {
  border-bottom: 1px solid var(--color-dark);
  padding: 1rem 0;
  margin: 1rem 0;
  background-color: transparent;
  transition: all 0.15s;
}
.contact--inputs div input:focus {
  border-color: var(--color-orange);
}
.contact--textarea textarea {
  background: rgba(43, 43, 43, 0.05);
  height: 8rem;
  width: 27rem;
  padding: 1rem;
  margin-top: 0.2rem;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.contact--form-long .contact--textarea textarea {
  width: 27rem;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
// Footer
.footer--simple {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  text-align: right;
  letter-spacing: 0.05em;
  padding-bottom: 54px;
}
.footer--logo {
  margin-right: 3rem;
}
.footer--facebook {
  margin-right: 1rem;
}
@media screen and (max-width: 1024px) {
  .footer--simple p {
    display: none;
  }
}
// Offer
.offer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10rem 0;
}
.offer--title {
  position: sticky;
  top: 0;
}
@media screen and (max-width: 1024px) {
  .offer {
    flex-direction: column;
    margin-top: 4rem;
  }
  .offer--title {
    font-size: 3rem;
    margin: 1rem 0;
  }
}

.offer--title {
  margin-right: 8rem;
  margin-bottom: auto;
  padding-top: 5rem;
}
// Card with number
.card-with-number {
  position: relative;
  margin: 6rem 0;
}

.card-with-number--title {
  margin: 2rem 0;
  margin-bottom: 0.5rem;
  text-align: left;
}
.card-with-number p {
  margin-right: 10rem;
}
@media screen and (min-width: 2000px) {
  .card-with-number p {
    margin-right: 15rem;
  }
}
.card-with-number--number {
  position: absolute;
  top: -3rem;
  left: -3rem;
}
@media screen and (max-width: 1024px) {
  .card-with-number {
    width: 100vw;
  }
  .card-with-number--img img {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
  }
  .card-with-number--title {
    margin-left: 1rem;
  }
  .card-with-number p {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .card-with-number--number {
    top: -1.5rem;
    left: 2.5rem;
    font-size: 3rem;
  }
}
// agent
.agent {
  text-align: center;
  position: relative;
  margin: 1rem;
}
.agent-container button {
  background: transparent;
}
.agent h3,
.agent p {
  text-align: center;
}
.agent--img img {
  max-width: 100%;
}
@media screen and (max-width: 1024px) {
  .agent--img img {
    width: 100%;
    object-fit: cover;
  }
}
.agent--text {
  padding: 2rem 0;
}
.agent--text h3 {
  padding: 1rem 0;
}
.agent--left {
  text-align: left;
  margin: 1rem;
}
.agent--left h3,
.agent--left p {
  text-align: left;
}
.agent button {
  bottom: 17.5rem;
  left: 12rem;
  position: relative;
  width: 13rem;
  height: 13rem;
}
.agent--left button {
  bottom: 17.5rem;
  left: 17.5%;
  position: relative;
  width: 13rem;
  height: 13rem;
}
@media screen and (max-width: 1024px) {
  .agent button,
  .agent--left button {
    position: static;
    margin-top: 2rem;
  }
  .agent--left button {
    margin-left: 22%;
  }
}

.agent--left .btn-without-number {
  bottom: 15.5rem;
}
.agent .btn-without-number {
  bottom: 15.5rem;
}
// get more info
.get-more {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4rem auto;
  @media screen and (min-width: 1024px) {
    max-width: 73rem;
  }
}

.get-more--title {
  max-width: 30rem;
}
.get-more-card-container {
  border-bottom: 1px solid rgb(199, 198, 198);
}
.get-more-card {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 50rem;
}
.get-more-card--title {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 140%;
  padding-right: 1em;
  /* identical to box height, or 45px */

  /* Dark */

  color: var(--color-dark);
}
.plus-icon {
  transition: 0.5s all;
  cursor: pointer;
}
.get-more-p {
  margin: 1rem 0;
  margin-top: -1rem;
  transition: 0.5s all;
}
.plus-icon-crossed {
  transform: rotate(45deg);
}
.plus-icon:active {
  transform: rotate(45deg);
}
@media screen and (max-width: 1024px) {
  .get-more {
    flex-direction: column-reverse;
    margin: 1rem;
  }
  .get-more div {
    margin-right: 6rem;
  }
  .get-more--title {
    margin-left: 0;
    margin-bottom: 2rem;
  }
  .get-more-card--title {
    font-size: 1.5rem;
  }
  .plus-icon {
    margin-right: -5.5rem;
  }
}
//  Latest news
.latest-news {
  background: var(--color-orange);
  height: 100vh;
  padding: 3rem auto;
  padding-right: 0;
  padding-left: 20rem;
  @media screen and (max-width: 1400px) {
    padding-left: 10%;
  }
}

.latest-news--title {
  display: flex;
  flex-direction: row;
}
.latest-news--title h2 {
  color: var(--color-body);
}
.latest-news--title-news {
  margin-left: 1.5rem;
}
.latest-news-carousel {
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  padding-right: 0;
}
.latest-news-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100rem;
  margin-inline: auto;
}
.latest-news-buttons {
  display: flex;
  flex-direction: row;
  margin-inline: auto;
}
.latest-news-buttons button {
  margin-right: 0.5rem;
}
.latest-news-cards {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  overflow-x: auto;
  scroll-behavior: smooth;
}
.latest-news-cards::-webkit-scrollbar {
  display: none;
}
.latest-news-cards div {
  margin-right: 10rem;
}
@media screen and (max-width: 1024px) {
  .latest-news {
    padding: 8rem 0;
    padding-right: 0;
  }
  .latest-news-cards div {
    margin-right: 1rem;
  }
  .latest-news--title {
    flex-direction: column;
    margin-left: 4rem;
    margin-bottom: -1.5rem;
  }
  .latest-news--title-news {
    margin-right: 16rem;
    margin-top: 0.5rem;
  }
  .latest-news--title h2 {
    font-size: 3.5rem;
    text-align: center;
    color: var(--color-body);
  }
  .latest-news-buttons {
    display: none;
  }
  .latest-news-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
// Svoy team
.svoy-team {
  margin: 5rem auto;
  max-width: 73rem;
}
.svoy-team h2 {
  margin-bottom: 3rem;
}
@media screen and (max-width: 1024px) {
  .svoy-team {
    margin: 1rem;
  }
}

// Second section
.second-section--los--title h3 {
  margin-bottom: 2rem;
}
.second-section--los--title {
  padding-right: 5%;
}

.second-section--los {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 7rem auto;
  max-width: 73rem;
}
.second-section--los-golf h3 {
  margin: 2.5rem 13.5rem;
}
.second-section--log-img {
  max-width: 100%;
}
@media screen and (max-width: 1024px) {
  .second-section--los {
    flex-direction: column-reverse;
    margin: 2rem 0;
    margin-top: 0;
  }
  .second-section--los--title {
    padding: 0;
    margin: 2.5rem 1rem;
  }
  .second-section--los-golf h3 {
    margin: 2.5rem 1rem;
  }
  .second-section--log-img {
    max-width: 100%;
    height: 20rem;
    object-fit: cover;
  }
  .second-section--los-golf img {
    max-width: 100%;
    height: 20rem;
    object-fit: cover;
  }
}
// we can figure out
.we-can {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8rem 9.5rem;
}
.we-can--title {
  width: 65%;
}
@media screen and (max-width: 1024px) {
  .we-can--title h2 {
    font-size: 3.5rem;
    align-items: center;
  }
}
.we-can--title-out {
  opacity: 0.2;
  position: relative;
  top: 4rem;
  left: -10.2rem;
  font-size: 7.8rem;
}
.we-can--problems {
  margin-right: 15rem;
  margin-top: 3rem;
  position: relative;

  &:hover > button {
    opacity: 1;
    pointer-events: initial;
    cursor: none;
  }
}
.we-can--problems button {
  position: absolute;
  top: 20rem;
  left: 32rem;
  background: transparent;
}
@media screen and (max-width: 1024px) {
  .we-can--title {
    width: 100%;
    padding-left: 2rem;
  }
  .we-can-title h2 {
    text-align: center;
  }
  .we-can--title-out {
    display: none;
  }
  .we-can {
    flex-direction: column;
    margin: 1rem;
  }
  .we-can--problems button {
    display: none;
  }
  .we-can--problems {
    margin: 1rem;
  }
}
// Problem
.problem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--color-dark);
  padding: 1.5rem 0;
  width: 170%;
}
.problem p {
  margin-right: 3.5rem;
}

@media screen and (max-width: 1024px) {
  .problem {
    width: 100%;
    margin-right: 1rem;
  }
  .problem p {
    margin-right: 2rem;
  }
}
// Test
.test-components {
  width: 100vw;
  text-align: left;
  margin: 0;
  padding: 2rem 1rem;
  @media screen and (min-width: 1024px) {
    padding: 0;
    width: auto;
    margin: 2rem 12rem 0;
  }
}
// Feature
.feature {
  max-width: 73rem;
  margin: 12rem auto;
  padding-inline: 1rem;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}
.features--title {
  margin-bottom: 4rem;
}
.feature-tag--title img {
  margin-right: 0.5em;
}
.feature-tag--title {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.feature-tag--title h5 {
  text-transform: uppercase;
  color: var(--color-dark);
}
.feature-tag--container {
  margin-bottom: 2em;
}
.feature-tag--tags {
  display: flex;
  flex-direction: row;
  max-width: 85%;
  gap: 0.75em;
  flex-wrap: wrap;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
}
.feature-tag--tag {
  padding: 0.5em 1em;
  background: var(--color-dark);
  border-radius: 3.5em;
}
.feature-tag--tag h6 {
  transition: color 0.8s;
}
.feature-tag--tag:hover h6 {
  color: #fff;
}
.feature-tag--tag-item {
  border: 2px solid var(--color-dark);
}
.feature-tag--tag h6 {
  color: var(--color-white);
  text-transform: uppercase;
}
.settings {
  width: 40%;
  margin-top: 10.5rem;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 5rem;
  }
}
.settings--text {
  /* Desktop/Body Huge */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 26px */

  /* Dark */

  color: #2b2b2b;
}
.setting--card {
  padding: 0.75rem 0;
}
// Card details
.card-details {
  max-width: 73rem;
  margin-top: -3em;
  padding-bottom: 2em;
}
.card-details--title {
  padding-inline: 1rem;
}
.card-details--title {
  max-width: 50%;
}
.card-details--title h4 {
  margin-bottom: 0.75rem;
  text-align: left;
}
.card-details--title p {
  max-width: 90%;
}
.card-details--img {
  object-fit: cover;
  width: 100%;
  height: 35rem;
}
.card-rent-details--img {
  object-fit: cover;
  width: 100%;

  height: 15rem;
}
.card-details-swap {
  display: flex;
  flex-direction: row;
  gap: 5em;
}
.card-details--price {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-details--price button {
  position: sticky;
}
.card-details-tag--title {
  margin-top: -5em;
}
.card-details--img-container button {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 5.5rem;
  left: 46%;
}
.card-details--img-container {
  position: relative;
}
.card-details--img-container button img {
  margin-right: 0.3rem;
}
.card-details-img--indexes {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  position: absolute;
  bottom: 7rem;
  left: 10%;
  width: 30%;
  flex-wrap: wrap;
}
.line-slider {
  background: var(--color-white);
  width: 3rem;
  height: 4px;
  cursor: pointer;
  position: relative;
  bottom: 0;
  transition: 0.5s all;
  opacity: 0.5;
}
.line-slider--container {
  height: 1rem;
  display: flex;
  align-items: center;
}
.card-rent-details-img--indexes {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  position: absolute;
  bottom: 1.5rem;
  left: 2rem;
  max-width: 100%;
}
.line-slider-rent {
  background: var(--color-white);
  width: 2.5rem;
  height: 4px;
  margin-inline: auto;
  cursor: pointer;
  position: relative;
  bottom: 0;
  transition: 0.5s all;
  opacity: 0.5;
}
.line-slider--container:hover > .line-slider,
.line-slider--container:hover > .line-slider-rent {
  height: 18px;
  opacity: 1;
}
.line-slider-rent:hover {
  height: 15px;
}
.indexes-number {
  color: var(--color-white);
  font-size: 0.75rem;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .card-details {
    flex-direction: column-reverse;
    gap: 2rem;
    padding: 2rem 1rem;
    margin-top: -5em;
  }
  .line-slider-rent {
    width: 3rem;
  }
  .card-details-img--indexes {
    width: 90%;
  }
  .card-details--title {
    max-width: 100%;
    padding-left: 0;
  }
  .card-details-tag--title {
    margin-top: 0;
  }
  .card-details--img {
    height: 25rem;
    object-fit: cover;
  }
  .btn--details {
    display: none;
  }
  .card-details-swap {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .card-details--img-container button {
    bottom: 2.8rem;
    left: 35%;
  }
}
.per-night-container {
  display: flex;
  flex-direction: row;
}
.per-night {
  margin-left: 0.5em;
  margin-bottom: 1em;
  font-size: 0.75em;
  max-width: 2rem;
}
// Calculate
.calcualte {
  max-width: 73rem;
  margin: 10rem auto;
}
.calculate-left {
  max-width: 100%;
}
.calculate-total {
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-dark);
  border-radius: 2rem;
  color: var(--color-dark);
  margin-bottom: 1em;
  max-width: 7em;
}
.calcualte-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.calcualte-card p,
.calculate-card-two-elements p {
  font-size: 1.25rem;
  padding: 0.5rem 0;
}
.calcualte-card {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  justify-content: left;
  grid-gap: 4rem;
}
.calculate-total-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.calculate--title-mob {
  display: none;
}
.btn--details {
  white-space: nowrap;
}
@media screen and (max-width: 1024px) {
  .calcualte {
    flex-direction: column;
  }
  .calculate--title-desktop {
    display: none;
  }

  .calculate-total-container {
    align-items: flex-start;
    margin-top: 3rem;
  }
  .calculate--title-mob {
    display: block;
  }
  .per-night {
    font-size: 1em;
  }
}
// Filter inputs
.filters {
  max-width: 55rem;
  margin: 3rem auto;
  gap: 1rem;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
    flex-direction: column;
    margin-left: -1rem;
  }
}
.filter-input--label {
  text-align: left;
  margin-left: 1rem;
  margin-bottom: -0.5rem;
  background: var(--color-body);
  overflow: hidden;
  clear: both;
  float: left;
  color: var(--color-dark);
  z-index: 5;
  position: relative;
  text-transform: uppercase;
  font-size: clamp(0.6rem, 0.75rem, 1rem);
}
.filter-input--from-to {
}
.filter-input--from-to {
  border: 1px solid var(--color-dark);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  clear: both;
  float: left;
}
.filter-input--from-to input,
.filter-input--from-to input::placeholder {
  font-size: 0.75rem;
  border: none;
  padding: 1rem 0.5rem;
  color: var(--color-dark);
  background: transparent;
  max-width: 4rem;
  text-align: center;
  clear: both;
}
.filter-input--input {
  border: 1px solid var(--color-dark);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  clear: both;
  z-index: -1;
  float: left;
}
.filter-input--input input,
.filter-input--input input::placeholder {
  font-size: 0.75rem;
  border: none;
  padding: 1rem 0.5rem;
  color: var(--color-dark);
  background: transparent;
  max-width: 8rem;
}
.filter--clear {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}
.filter--clear img {
  width: 1rem;
}
.btn--save {
  display: none;
}
.filter--title {
  display: none;
}
.filter--container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 60rem;
  margin: 0 auto;
  gap: 1rem;
}
@media screen and (max-width: 1024px) {
  .filter-input--input input,
  .filter-input--input input::placeholder {
    // max-width: 100%;
    font-size: 1.25rem;
    max-width: 160px;
  }
  .filter-input--container {
    max-width: 100%;
  }
  .filter-input--from-to input,
  .filter-input--from-to input::placeholder {
    // max-width: 20%;
    max-width: 76px;
    font-size: 1.25rem;
  }
  .filter-input--date input {
    max-width: 165px;
    text-align: right;
  }
  .btn--save {
    display: block;
    padding: 2rem;
  }
  .filter--clear {
    margin: -1rem auto;
    padding-inline: auto;
    padding-right: 3rem;
  }
  .filter--title {
    display: block;
    margin-left: 1rem;
  }
  .filter--container {
    flex-direction: column;
    margin: 6rem auto;
  }
}
// Rent card
.rent-buy--top {
  height: 100%;
  &:hover > .btn--circle-animated {
    opacity: 1;
    pointer-events: initial;
    cursor: none;
  }
}
.rent-buy--top .btn--circle-animated {
  background: transparent;
  right: 15rem;
  z-index: 100;
}
.rent-card {
  max-width: 100%;
}
.rent-card--rooms {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}
.rent-card--rooms h5 {
  color: var(--color-dark);
}
.rent-card p {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}
.rent-card--price {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-card {
  max-width: 95rem;
  margin-inline: auto;
  margin-bottom: 4rem;
  padding-inline: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
}
.per-night-card {
  margin-bottom: 5rem;
}
.card-rent-images {
  position: relative;
}
.navigationItem {
  font-size: 12px;
  font-weight: 600;
  font-family: $Commissioner;
  color: var(--color-dark);
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  &::after {
    margin-top: 2px;
    content: "";
    display: block;
    width: 0;
    height: 1.7px;
    background-color: var(--color-dark);
    transition: 0.3s width;
  }
  &:hover::after {
    width: 100%;
  }
}

.navigationItem--language {
  display: flex;
  justify-content: center;
  align-items: center;
  order: 1;
  cursor: pointer;
  & > img {
    margin: 0 0 3.7px 8px;
  }
}
.filter-top--container {
  max-width: 95rem;
  align-items: center;
  margin-top: -93px;
}
.filter-top--buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
// search page
.filter-top--text {
  color: var(--color-dark);
  opacity: 0.7;
  cursor: pointer;
  /* Desktop/Description */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: 0.1em;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
.filter-top--text-on {
  /* Desktop/Description */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: 0.1em;
  color: var(--color-dark);
  border-bottom: 2px solid var(--color-dark);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
.filter-top--text-on p,
.filter-top--text p {
  font-size: 12px;
}
.only-desktop {
  display: flex;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.search-card--map-container {
  position: relative;
}
.search-card--map-container .map {
  z-index: 2;
}
.search-card--with-map {
  position: absolute;
  top: 0;
  z-index: 0;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 73%;
  margin-right: auto;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
// Blog
.blog {
  margin-inline: auto;
  padding-inline: auto;
  max-width: 85rem;
}
.blog--top {
  margin: 3rem auto;
  max-width: 73rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.blog--top h1 {
  color: var(--color-dark);
}
.blog--top .feature-tag--tags {
  margin-inline: auto;
  margin-top: 1rem;
  cursor: pointer;
}
.blog--top .feature-tag--tags .feature-tag--tag {
  border: 2px solid var(--color-dark);
}
.feature--tag-blog {
  background: var(--color-body);
  cursor: pointer;
}
.feature--tag-blog h6 {
  color: var(--color-dark);
}
.blog--cards {
  grid-gap: 0.75rem;
  max-width: 75rem;
  margin-inline: auto;
  margin: 4rem auto;
}
.blog--cards div {
  max-width: 100%;
}
.blog--cards img {
  object-fit: cover;
  height: 20rem;
  max-width: 100%;
}
@media screen and (max-width: 1024px) {
  .blog--cards {
    padding-inline: 1rem;
  }
  .blog--top {
    padding-left: 1rem;
  }
  .blog h1 {
    font-size: 3.5rem;
  }
  .blog--top .feature-tag--tags {
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    margin-top: 2rem;
    margin-bottom: -2rem;
    padding-right: -1rem;
  }
  .blog--top .feature-tag--tags::-webkit-scrollbar {
    display: none;
  }
  .blog--cards img {
    width: 100%;
  }
}
// Contact
.contact {
  margin: 3rem auto;
  padding-inline: 1rem;
  max-width: 78rem;
}
.contact--title {
  display: block;
  flex-direction: column;
}
.contact--title p {
  max-width: 30rem;
  margin: 2rem auto;
  text-align: center;
}
.contact--title h1 {
  color: var(--color-dark);
  font-size: 120px;
  white-space: none;
}
.contact--items {
  margin: 8rem auto;
}
.contact--items-sell {
  max-width: 60rem;
}
@media screen and (max-width: 1200px) {
  .contact--title h1 {
    font-size: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .contact--items {
    flex-direction: column;
    padding-inline: 0;
    margin: 3rem auto;
  }
  .contact--title h1 {
    font-size: 3.5rem;
  }
}
// services
.services--line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4rem auto;
}
.services--line h4 {
  padding: 3rem 0;
  max-width: 60rem;
  margin-inline: auto;
  text-align: left;
}
.line--bold {
  width: 6px;
  background: var(--color-dark);
}
@media screen and (max-width: 1024px) {
  .services--line h4 {
    padding: 1rem;
  }
  .line--bold {
    width: 10px;
  }
}
.buy-item-page {
  margin-top: -93px;
}
// Map
.map {
  position: relative;
}
.map-container {
  transition: 0.5s all;
  height: 90vh;
}
.sidebar {
  position: absolute;
  padding: 2rem;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  z-index: 1;
  transition: 0.5s all;
  // margin-left: 75%;
}
.btn--map {
  background: var(--color-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition: 0.15s all;
}
.btn--3d {
  pointer-events: none;
}

.btn--map img {
  padding: 0.5rem;
  color: var(--color-white);
  outline: none;
}
.btn--map:hover {
  opacity: 0.8;
}
@media screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
  .map-container {
    height: 100vh;
  }
}
// map card
.map-card {
  max-width: 11rem;
  padding: 0.5rem;
  position: relative;
  background: var(--color-white);
  box-shadow: 0px 5px 10px rgba(43, 43, 43, 0.15);
}
.map-card--img {
  width: 100%;
  height: 6rem;
  object-fit: cover;
}
.map-card--description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}
.map-card--description h5 {
  color: var(--color-dark);
}
.map-card--price {
  position: absolute;
  padding: 0.5rem 1rem;
  background: var(--color-dark);
  border-radius: 1rem;
  left: 3.5rem;
  bottom: -3rem;
}
.map-card--price h5 {
  color: var(--color-white);
}
// blog item
.blog-item {
  margin-inline: auto;
  padding-inline: 1rem;
  max-width: 50rem;
  margin-top: -60px;
}
.blog-item h3 {
  margin-bottom: 1rem;
}
.blog-item img {
  width: 100%;
  object-fit: cover;
}
.blog-item--tags {
  margin: 1rem 0;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.blog-item--tags-add {
  display: flex;
  justify-content: start;
  gap: 1rem;
  flex-direction: row;
}
.blog-item--tags-info {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.blog--item-component {
  margin: 4rem 0;
  width: 100%;
}
.blog--item-component img {
  width: 100%;
  object-fit: cover;
}
.blog--item-component-photo {
  text-align: center;
  margin: 1.25rem 0;
}
.blog--item-bottom {
  margin: 8rem 0;
}
@media screen and (max-width: 1024px) {
  .blog-item {
    max-width: 100%;
    margin-top: -90px;
  }
  .blog-item--tags {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .blog-item img {
    height: 18rem;
  }
  .blog--item-component img {
    height: 15rem;
  }
}

// .blog-item-button {
//   position: relative;
//   max-height: 30rem;
//   &:hover > button {
//     opacity: 1;
//     pointer-events: initial;
//     cursor: none;
//   }
// }
.blog-item-button {
  position: relative;
  height: 15rem;
  &:hover > button {
    opacity: 1;
    pointer-events: initial;
    cursor: none;
  }
}
.blog-item-button button {
  opacity: 1;
  top: 0;
  background: transparent;
}
