@import "../../scss/variables.scss";

.sectionSlider {
    width: 1440px;
    height: 719px;
    margin: 50px auto 228px;
    text-align: center;
    position: relative;
}

.sliderContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn {
    width: 70px;
    height: 70px;
    background-color: transparent;
    border-radius: 100px;
    border: 1px solid var(--color-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.3s ease-in-out;
    z-index: 999;
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        border-radius: 100px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: scale(0);
        transition: 0.3s ease-in-out;
        background-color: var(--color-orange);
    }
    &:hover::after {
        transform: scale(1.3);
    }
    &:hover::before {
        filter: brightness(0) invert(1);
    }
}

.btnLeft {
    position: absolute;
    top: 40%;
    left: 69px;
    &::before {
        transition: 0.3s ease-in-out;
        content: url("../../static/ArrowLeft.svg");
    }
}

.btnRight {
    position: absolute;
    top: 40%;
    right: 63px;
    &::before {
        transition: 0.3s ease-in-out;
        content: url("../../static/ArrowRight.svg");
    }
}

.wrapperSlider {
    margin: 0 auto;
    overflow: hidden;
    display: flex;
}

.slider {
    margin: 0 auto;
    display: flex;
    z-index: 2;
    gap: 82px;
    transition: 1s ease-in-out;
}

.sliderCard {
    width: 1200px;
    height: 649px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    clip-path: polygon(0 20%, 100% 20%, 100% 80%, 0 80%);
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 80%, 0 80%);
    transition: clip-path 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: -webkit-clip-path 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.topContainer {
    width: 95%;
    padding: 30px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
}

.topTitle {
    font-family: $Commissioner;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--color-white);
}

.topWrapperText {
    text-align: right;
}

.bottomContainer {
    width: 95%;
    padding: 0 30px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.wrapperBottomText {
    text-align: left;
}

.bottomTitle {
    font-family: $Commissioner;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--color-white);
    margin-bottom: 10px;
}

.bottomText {
    font-family: $Commissioner;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: var(--color-white);
    padding-right: 425px;
}

.bottomWrapperAddInfo {
    text-align: right;
}

.wrapperBottomAddText {
    width: 182px;
    display: flex;
    justify-content: space-between;
}

.bottomTextElement {
    text-transform: uppercase;
    margin-left: 9px;
    padding-right: 0;
}

.btnMore {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: var(--color-white);
    filter: brightness(0) invert(1);
}

.sliderCard--active {
    width: 1200px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.sectionTitle {
    font-size: 100px !important;
    position: absolute;
    bottom: -3px;
    right: 50px;
}