@import "../../scss/variables.scss";

.footerLong {
  background: var(--color-dark);
  padding: 7.5rem 10rem;
}
.footerLongList {
  text-align: left;
  padding-right: 15rem;
}
.footerLongList ul {
  padding-top: 0.4rem;
}
.footerLongListContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-inline: auto;
  align-items: center;
  padding-bottom: 7.5rem;
}
.footerLongNumber {
  color: var(--color-white);
  padding-top: 1.2rem;
}
.footerLongQuestions {
  text-align: right;
  margin-bottom: auto;
}

.footerLongCopyright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}
.footerLongCopyright div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footerLongLine {
  padding: 1rem 0;
  padding-bottom: 7rem;
}
.footerLongLine div div p {
  padding-left: 4rem;
}
.footerLongLine p {
  color: var(--color-white);
}
.footerLongBottomSection {
  position: relative;
}
.btn--footer {
  position: absolute;
  top: 6rem;
  z-index: 10;
}
.footerLongSvoy {
  font-size: 140px;
}
@media screen and (max-width: 1500px) {
  .footerLongSvoy {
    font-size: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .footerLongSvoy {
    font-size: 90px;
  }
}
@media screen and (max-width: 1024px) {
  .footerLong {
    padding: 5rem 1rem;
  }
  .footerLongListContainer {
    flex-direction: row-reverse;
    justify-content: space-evenly;
  }
  .footerLongList {
    padding-right: 4rem;
    padding-top: 5rem;
  }

  .topSection {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;
    margin: 0;
    padding-bottom: 3rem;
    margin-left: -5rem;
  }
  .footerLongQuestions {
    margin-bottom: 1rem;
    text-align: left;
  }
  .btn--footer {
    display: none;
  }
  .footerLongCopyright {
    flex-direction: column;
  }
  .footerLongCopyright div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footerLongLine div div p {
    font-size: 1rem;
    padding: 0;
    margin-top: 3rem;
  }
  .footerLongSvoy {
    font-size: 6rem;
    text-align: left;
  }
}

.listItem {
  text-align: left;
}
.footerItem {
  /* Desktop/Body Huge */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 26px */

  /* White */

  color: var(--color-white);
  text-align: left;
  margin: 0.8rem 0;
}
