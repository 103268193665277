@import "../../scss/variables.scss";

.main {
  max-width: 1200px;
  width: 100%;
  min-height: 100vh;
  text-align: left;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 1024px) {
    // margin: 2rem 12rem 0;
    padding: 0 1rem;
    // margin: 90px auto;
  }
}

.boxElement {
  padding: 7px 15px;
  border-radius: 100px;
  background-color: $orangeColor;
  color: $whiteColor;
  font-size: 12px;
  font-weight: 600;
  font-family: $Commissioner;
  text-transform: uppercase;
}

.socialNetworks {
  width: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
