// Fonts
@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;600;700;800;900&display=swap");

// font-family: 'Commissioner', sans-serif;
$Commissioner: "Commissioner", sans-serif;

@import url("https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap");

// font-family: 'Tenor Sans', sans-serif;
$Tenor: "Tenor Sans", sans-serif;

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap");

// font-family: 'Mulish', sans-serif;
$Mulish: "Mulish", sans-serif;

// Colors
$darkColor: rgba(43, 43, 43, 1);
$orangeColor: rgba(246, 136, 77, 1);
$whiteColor: #fff;

:root {
  --color-orange: #f6884d;
  --color-body: #fdf7ee;
  --color-dark: #2b2b2b;
  --color-white: #ffffff;
  border-radius: 3rem;
}
// Typography

::placeholder {
  /* Desktop/Body */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 160%;
  /* identical to box height, or 26px */

  text-align: left;

  /* Dark */

  color: var(--color-dark);

  opacity: 0.2;
}
h1 {
  font-family: "Tenor Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 165px;
  line-height: 100%;
  /* identical to box height, or 165px */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* White */

  color: var(--color-white);
}

h2 {
  font-family: "Tenor Sans", sans-serif;
  /* Desktop/H2 */
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 100%;
  text-align: left;
  /* or 100px */

  letter-spacing: 0.03em;
  text-transform: uppercase;

  /* Dark */

  color: var(--color-dark);
  @media screen and (max-width: 1024px) {
    font-size: 3.5rem;
  }
  @media screen and (min-width: 1440px) {
    font-size: 6.25rem;
  }
}
h3 {
  /* Desktop/H3 */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 2.1875rem;
  line-height: 100%;
  text-align: left;

  /* or 35px */

  /* Dark */

  color: var(--color-dark);
}
h4 {
  /* Desktop/H4 */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 140%;
  /* identical to box height, or 42px */

  /* Dark */

  color: var(--color-dark);
}
h6 {
  /* Desktop/H6 */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 130%;
  /* or 19px */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* Dark */

  color: #2b2b2b;
}
p {
  /* Desktop/Body */
  text-align: left;

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 160%;
  /* or 26px */

  /* Dark */

  color: var(--color-dark);
  @media screen and (max-width: 1024px) {
    /* Mobile/Body Small */

    font-family: "Commissioner";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 160%;
    /* or 19px */

    /* Dark */

    color: #2b2b2b;
  }
}
label {
  /* Desktop/Description */

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 130%;
  /* identical to box height, or 16px */

  letter-spacing: 0.1em;

  /* Dark */

  color: var(--color-dark);
}
a {
  cursor: pointer;
}

#root {
  // width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--color-body);
  overflow-x: hidden;
  position: relative;
}
@media screen and (min-width: 2000px) {
  h2 {
    font-size: 8rem;
  }
  p {
    font-size: 1.6rem;
  }
  h3 {
    font-size: 3rem;
  }
  h4 {
    font-size: 2.5rem;
  }
}

// .mainPage {
//   margin: 0 auto;
//   text-align: center;
//   position: relative;
// }

.header {
  margin: 0 auto;
  background-color: var(--color-dark);
}

.headerLogo {
  filter: brightness(0) invert(1);
}

.headerNavigation {
  filter: brightness(0) invert(1);
}

.offerSection {
  height: 100vh;
  padding-top: 28px;
  pointer-events: initial;
  // top: 70px;
  & > div {
    height: 755px;
    opacity: 1;
    pointer-events: initial;
  }
}
