@import "../../scss/variables.scss";

.gallerySection {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 256px;
    text-align: center;
}

.wrapperTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    margin-bottom: 20px;
    position: relative;
}

.button {
    align-self: flex-end;
    position: relative;
    bottom: 13px;
}

.galleryContainer {
    width: 1200px;
    display: grid;
    gap: 20px;
    grid-template: repeat(3, fit-content) / repeat(3, fit-content);
    grid-template-areas:
        "image1 image2 image3"
        "image4 image2 image3"
        "image4 image5 image5";
}

.image1 {
    grid-area: image1;
}

.image2 {
    grid-area: image2;
}

.image3 {
    grid-area: image3;
}

.image4 {
    grid-area: image4;
}

.image5 {
    grid-area: image5;
}
