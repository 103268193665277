@import "../../scss/variables.scss";
.btn {
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    font-family: $Commissioner;
    text-transform: uppercase;
    color: var(--color-dark);
    &::after {
        content: url("../../static/arrow.svg");
        position: relative;
        margin-left: 0.8rem;
        left: 0;
        transition: 0.2s left;
    }
    &:hover::after {
        left: 1.1rem;
    }
}
