body {
    margin: 0 auto;
    text-align: center;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
}
article,
aside,
footer,
header,
html,
main {
    scroll-behavior: smooth;
    box-sizing: border-box;
}
a,
article,
aside,
body,
button,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
img,
input,
li,
p,
main,
select,
span,
textarea,
ul {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    line-height: 1.2;
    box-sizing: border-box;
}
:active,
:focus {
    outline: 0;
}
:after,
:before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
button,
input,
textarea {
    font-family: inherit;
}
input::-ms-clear {
    display: none;
}
button {
    cursor: pointer;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
a,
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
ul li {
    list-style: none;
}
img {
    vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}
[hidden] {
    display: none;
}

::-webkit-scrollbar-thumb {
    background-color: #878385;
    border-radius: 4px;
}
::-webkit-scrollbar {
    height: 3px;
    width: 7px;
    background-color: transparent;
}
