@import "../../scss/variables.scss";

.navigation {
    max-width: 789px;
    width: 100%;
    text-align: center;
    z-index: 999;
    transition: 0.8s ease-in-out;
    -webkit-transition: 0.8s ease-in-out;
}

.navigationContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navigationItem {
    font-size: 12px;
    font-weight: 600;
    font-family: $Commissioner;
    color: var(--color-dark);
    line-height: 16px;
    letter-spacing: 0.1em;
    text-align: left;
    text-transform: uppercase;
    &::after {
        margin-top: 2px;
        content: "";
        display: block;
        width: 0;
        height: 1.7px;
        background-color: var(--color-dark);
        transition: 0.3s width;
    }
    &:hover::after {
        width: 100%;
    }
}

.navigationItem--ukrainian {
    margin-right: 64px;
    background-color: transparent;
}

.navigationItem--language {
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
    cursor: pointer;
    & > img {
        margin: 0 0 3.7px 8px;
    }
}
