@import "../../scss/variables.scss";

.sectionAbout {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 132px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 232px;
    text-align: left;
  }
}

.sectionSubtitle {
  font-size: 15px;
  font-weight: 600;
  font-family: $Commissioner;
  text-transform: uppercase;
  color: $darkColor;
  margin-bottom: 28px;
}

.sectionTitle {
  font-size: 30px;
  font-weight: 400;
  font-family: $Tenor;
  color: $darkColor;
  text-transform: uppercase;
  margin-bottom: 17px;
  @media screen and (min-width: 1024px) {
    font-size: 100px;
  }
}

.wrapperAddInformation {
  max-width: 717px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    justify-content: space-between;
    flex-direction: row;
    margin: 0;
  }
}

.sectionParagraph {
  font-size: 16px;
  font-weight: 400;
  font-family: $Commissioner;
  color: $darkColor;
  margin-bottom: 20px;
  font-size: 1.2rem;

  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
}

.btnContent {
  width: 121px;
}
