@import "../../scss/variables.scss";

.button {
    padding: 21px 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-radius: 100px;
    color: $darkColor;
    font-size: 15px;
    font-weight: 600;
    font-family: $Commissioner;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid $darkColor;
    &::after {
        content: url("../../static/arrowBottom.svg");
        position: relative;
        top: 0;
        transition: 0.2s top;
    }
    &:hover::after {
        top: 12px;
    }
    @media screen and (min-width: 1440px) {
        padding: 31px 72px;
    }
}
