@import "../../scss/variables.scss";

.card {
  width: 387px;
  text-align: left;
  margin: 20px auto;
}

.cardImage {
  margin-bottom: 24px;
}

.cardTitle {
  font-size: 30px;
  margin-bottom: 14px;
}

.cardParagraph {
  margin-bottom: 24px;
}

.textDarkColor {
  color: var(--color-dark);
}

.textWhiteColor {
  color: var(--color-white);
  filter: brightness(0) invert(1);
}
@media screen and (max-width: 1024px) {
  .cardImage {
    width: 22rem;
    height: 20rem;
    margin-right: 10rem;
    object-fit: cover;
  }
}
