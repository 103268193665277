@import "../../scss/variables.scss";

.sectionStat {
    max-width: 1440px;
    width: 100%;
    text-align: center;
    margin: 0 auto 339px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.sectionSubtitle {
    font-family: $Tenor;
    font-size: 80px;
    letter-spacing: 0.05em;
    line-height: 100%;
}

.wrapperSubtitle {
    text-align: left;
}

.sectionSubtitleItem {
    font-family: $Commissioner;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
}

.sectionCard {
    max-width: 590px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px 0 40px;
    background-color: var(--color-orange);
}

.sectionBtnMore {
    color: var(--color-white);
    filter: brightness(0) invert(1);
}

.cardSubtitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    font-family: $Commissioner;
    color: var(--color-white);
    margin-bottom: 8px;
}

.cardTitle {
    font-family: $Tenor;
    font-size: 120px;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 100%;
    color: var(--color-white);
    margin-bottom: 60px;
}