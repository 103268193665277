@import "../../scss/variables.scss";

// About

.about-us--article {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.about-us--description {
  max-width: 373px;
  width: 100%;
  margin-right: 3rem;
  margin-right: 8rem;
}
.about-us--description p {
  margin-bottom: 1rem;
}

.about-us--title {
  max-width: 598px;
  width: 100%;
}
