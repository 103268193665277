@import "../../scss/variables.scss";

.section {
    max-width: 1199px;
    width: 100%;
    text-align: center;
    margin: 0 auto 139px;
}

.animatedLogoWrapper {
    max-width: 731px;
    width: 100%;
    height: 115px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    &:hover > button {
        opacity: 1;
        pointer-events: initial;
        cursor: none;
    }
}

.sectionTitle {
    font-size: 115px;
    font-weight: 400;
    font-family: $Tenor;
    text-transform: uppercase;
    color: var(--color-dark);
    letter-spacing: 0.03em;
    line-height: normal;
}

.sectionTitleAnimated {
    cursor: pointer;
    position: relative;
    color: transparent;
    -webkit-text-stroke: 2px var(--color-dark);
    &::before {
        content: attr(data-fill-title);
        position: absolute;
        width: 100%;
        opacity: 1;
        height: 100%;
        overflow: hidden;
        color: var(--color-dark);
        -webkit-text-stroke: 0 var(--color-dark);
        transition: opacity 411ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &::after {
        content: "";
        background-color: var(--color-orange);
        display: block;
        position: absolute;
        bottom: 2px;
        width: 0;
        height: 6px;
        overflow: hidden;
        transition: width 411ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &:hover::before {
        opacity: 0;
    }
    &:hover::after {
        width: 100%;
    }
    &:hover {
        cursor: none;
    }
}

.LogoWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
}

.linkBtn {
    padding: 30px 91px;
    font-family: $Commissioner;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
    position: relative;
    border: 2px solid transparent;
    background: linear-gradient(var(--color-body), var(--color-body)) padding-box,
        linear-gradient(93.02deg, #0057b8 0%, #ffd700 97.41%) border-box;
    border-radius: 100px;
    transition: all 350ms linear;
    z-index: 99;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
        font-size: 0;
        z-index: -1;
        content: "";
        position: absolute;
        bottom: -120px;
        transform: skew(-60deg, -166deg);
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 350ms linear;
        background: linear-gradient(93.02deg, #0057b8 0%, #ffd700 97.41%);
        border-radius: 100px;
    }
    &:hover {
        border: 2px solid transparent;
        background: linear-gradient(var(--color-white), var(--color-white)) border-box;
    }
    &::before {
        content: url("../../static/emblem.svg");
        margin-right: 8px;
        transition: 350ms opacity;
        opacity: 0;
    }
    &:hover::before {
        opacity: 1;
    }
    &:hover::after {
        bottom: 0;
        border-radius: 3rem;
        transform: skew(-83deg, -150deg);
        background: linear-gradient(9.02deg, #0057b8 25%, #ffd700 74.41%);
    }
    &:hover > span {
        background: var(--color-white);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.linkBtnText {
    background: linear-gradient(93.02deg, #0057b8 0%, #ffd700 97.41%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2px;
}

.sectionSubtitle {
    line-height: 19.5px;
    position: relative;
    top: -17px;
    left: -105px;
}

// special offer section

.specialOfferSection {
    margin: 0 auto;
    width: 100vw;
    z-index: 999;
    position: absolute;
    top: 0;
    text-align: center;
    height: 0;
    pointer-events: none;
    transition: 0.8s ease-in-out;
    -webkit-transition: 0.8s ease-in-out;
    background-color: var(--color-dark);
    & > div {
        opacity: 0;
        height: 0;
        pointer-events: none;
    }
}

.specialOfferSectionContent {
    max-width: 1199px;
    width: 100%;
    height: 755px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    transition: 0.7s opacity;
    -webkit-transition: 0.7s opacity;
}

.closeBtn {
    font-size: 15px;
    font-weight: 500;
    font-family: $Commissioner;
    color: var(--color-white);
    text-transform: uppercase;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 43px;
    &::before {
        content: url("../../static/cross.svg");
        margin-right: 18px;
    }
}

.specialOfferSectionTitle {
    font-size: 120px;
    color: var(--color-white);
    letter-spacing: 0.05em;
}

.ukrainianHerb {
    width: 85px;
    height: 85px;
    border-radius: 100%;
    border: 1px solid var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: url("../../static/herb.svg");
    }
}

.ukrainianHerb--left {
    margin-left: 63px;
}

.ukrainianHerb--right {
    margin-right: 82px;
}

.buttonOffer {
    color: var(--color-white) !important;
    border-color: var(--color-white) !important;
    &::after {
        filter: brightness(0) invert(1);
    }
}

.wrapperSectionContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapperSectionContent--width {
    width: 93%;
}

.wrapperSectionContent--end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.wrapperSectionContent-mb {
    padding-bottom: 5px;
}

.specialOfferParagraph {
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    font-family: $Commissioner;
    color: var(--color-white);
    padding: 0 375px;
    position: relative;
    left: -186px;
}

.offerSocialLink {
    width: 168px;
    display: flex;
    justify-content: space-between;
    color: var(--color-white);
    font-size: 12px;
    font-weight: 600;
    font-family: $Commissioner;
    line-height: 130%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: left;
    position: absolute;
    right: 80px;
    bottom: 72px;
    &::before {
        content: url("../../static/whiteInstIcon.svg");
        margin-right: 12.5px;
    }
}
