.sectionGallery {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 183px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 183px;
  @media screen and (min-width: 1024px) {
    justify-content: space-between;
    flex-direction: row;
    padding: 130px 0 130px;
  }
}

.image {
  margin-bottom: 17px;
  // width: 345px;
  &:last-child {
    margin-bottom: 0;
  }
  // @media screen and (min-width: 1024px) {
  //   width: auto;
  // }
}

.image2 {
  position: relative;
  @media screen and (min-width: 1024px) {
    top: -130px;
  }
}

.image3 {
  position: relative;
  @media screen and (min-width: 1024px) {
    bottom: -130px;
  }
}
